import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

function Contact() {

    return (
        <section id="Contact" className="Contact">
            <div className="Contact_Content">
                <div className="Contact_Box">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62701.7361919678!2d106.74353066337709!3d10.82213793185458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317527ce496f91f1%3A0x99a4aabc4bac329f!2sMASTERI%20AN%20PH%C3%9A%20-%20BLOCK%20RIO!5e0!3m2!1sko!2skr!4v1677059886569!5m2!1sko!2skr" 
                    title="google map"
                    className="Contact_Map" 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <form className="Info">
                    <p>CHOI BYOUNG KWON</p>
                    <p>CÔNG TY TNHH JB DESIGN VIETNAM</p>
                    <p>A310 block Rio, MASTERI AN PHU, 179 Xa Lo Ha Noi, Thao Dien, HCMC</p>
                    <p>choi.byoungkwon@gmail.com</p>   
                    <p>093-727-6801</p>
                    <a href='https://www.instagram.com/jb_design.vn/' target='_blank' rel="noreferrer noopener">
                        <FontAwesomeIcon className="insta-icon" icon={faInstagram} size="sm"/>
                        <p>instagram</p>
                        </a>
                </form>
            </div>
        </section>
    );
}

export default Contact;