import './ImageGrid.css';

function ImageGrid({ projects, setSelectedProject }) {

    return (
        <div className="img-grid">
            { projects && projects.map(project => (
                <div className="project" key={project.ProjectName} 
                    onClick={() => setSelectedProject(project.images)}>
                    <img src={project.images[0]} alt="first img"/>
                    <div className="image-info">
                        <p className="image-date">{project.Date}</p>
                        <p className="image-name">{project.ProjectName}</p  >
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ImageGrid;