import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header'; 
import Home from './Components/Home';
import Projects from './Components/Projects';
import Contact from './Components/Contact';
import Concepts from './Components/Concepts';
import Footer from './Components/Footer';
import useFirestore from './Hooks/useFirestore';

function App() {

  const doc = useFirestore("Projects");

  return (
      <Router>
        <div id="App">
          <script src="https://kit.fontawesome.com/ac95bfe606.js" crossorigin="anonymous"></script>
          <Header />

          <div id="Content" className="Main"> 
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/Projects" element={<Projects projects={doc}/>}/>
              <Route path="/Contact" element={<Contact/>}/>
              <Route path="/Concepts" element={<Concepts/>}/>
            </Routes>
          </div>
            
          <Footer />
        </div>
      </Router>
  );
}

export default App;
