import { useState, useEffect } from "react";
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import firebasedb from '../firebase/config';

const useFirestore = (projects) => {
    const [ docs, setDocs ] = useState([]);

    useEffect(() => { 
        async function fetchDoc() {
            const q = query(collection(firebasedb, projects), orderBy('Timestamp', 'desc'));
                
            const snapshot = await getDocs(q); 
            let documents = [];
            snapshot.forEach(doc => {
                doc.data();
                documents.push({...doc.data(), id: doc.id});
            })
            setDocs(documents); 

            return q;
            
        }
        fetchDoc();
    }, [projects]);

    return docs;
}

export default useFirestore; 