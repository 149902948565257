import './Footer.css'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

function Footer() {
  return (
    <footer className="Active Footer">
        <div className="Footer_Info"> 
          <p>Tầng 3 <br/> A310 block Rio, MASTERI AN PHU, 179 Xa Lo Ha Noi, Thao Dien, HCMC <br/> số 179 Xa Lộ Hà Nội Phường Thảo Điền <br/> Thành phố Hồ Chí Minh <br/> Việt Nam.</p>
        </div>
        <div className="Footer_Contact">
          <p>CÔNG TY TNHH JB DESIGN VIETNAM</p>
          <p>TEL: 093-727-6801</p>
          <p>Email: choi.byoungkwon@gmail.com</p>
        </div>
        <div className="Footer_SNS">
          <div className="Footer_Instagram">
            <a href="https://www.instagram.com/j_homes_design/" target="_blank" rel="noreferrer" className="Footer_Instagram_Text">
              <FontAwesomeIcon icon={faInstagram} size="sm"/>
              Instagram 
            </a>
          </div>
        </div>
    </footer>
  )
}

export default Footer