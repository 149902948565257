import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import './Header.css';
function Header() {

  const navRef = useRef();
  const [open, setOpen] = useState(false);
  const showNavBar = () => {
    navRef.current.classList.toggle("Mobile_Nav");
    setOpen(!open);
  }

  const closeNavBar = () => {
    navRef.current.classList.remove("Mobile_Nav");
    setOpen(false);
  }

  return ( 
      <header className="Active Header">
        <h1 className="Header_Logo">
          JB Design
        </h1>

        <li className="Header_Links" ref={navRef}>
          <Link className="Header_Link" id="Home_Link" to="/" onClick={closeNavBar}>Home</Link>
          <Link className="Header_Link" id="Design_Link" to="Projects" onClick={closeNavBar}>Projects</Link>
          <Link className="Header_Link" id="Contact_Link" to="Contact" onClick={closeNavBar}>Contact</Link>
          <Link className="Header_Link" id="Concept_Link" to="Concepts" onClick={closeNavBar}>Concepts</Link>
        </li>

        <button className="Header_Button" onClick={showNavBar}>
          {open ? <FontAwesomeIcon icon={faTimes} color="white" size="3x"/> : <FontAwesomeIcon icon={faBars} color="white" size="3x"/> }
        </button>
      </header>
  );
}

export default Header;