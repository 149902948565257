import './Home.css';
import logo from "./Logo.svg";

function Home() {
    return (
        <section id="Home" className="Home">
            <img className="Home_Logo" src={logo} alt="logo"/>
            <div className="Home_Backdrop"/>

        </section>
    );
}

export default Home;
