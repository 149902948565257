// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD7t4-G5Rw8FFfSoINcWhTRVR7Y7PsEvNA",
  authDomain: "jdesign-web.firebaseapp.com",
  projectId: "jdesign-web",
  storageBucket: "jdesign-web.appspot.com",
  messagingSenderId: "757949188976",
  appId: "1:757949188976:web:48735dfcf2f5ff017cddb5"
};

// Initialize Firebase
const firebaseapp = initializeApp(firebaseConfig);
const firebasedb = getFirestore(firebaseapp);

export default firebasedb;