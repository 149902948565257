import './ImageModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

const ImageModal = ({selectedProject, setSelectedProject}) => {

    const numImages = selectedProject.length;
    const [index, setIndex] = useState(0);
    const [image, setImage] = useState(selectedProject[0]);
    const handleClick = (e) => {
        if( e.target.classList.contains('Modal-Backdrop')) {
            setSelectedProject(null);
        }
    }
    const handleSwipe = useSwipeable({
        onSwipedLeft: () => {
            setIndex((index+1)%numImages)
        },
        onSwipedRight: () => {
            if(index === 0) setIndex(numImages-1)
                else setIndex(index-1)
        }
    })

    useEffect(() => {
        setImage(selectedProject[index]);
    }, [index, selectedProject]);

    return (
        <div className="Modal-Backdrop" onClick={handleClick }>
            <FontAwesomeIcon className="modalbutton" id="modalleft" icon={faArrowLeft} size="3x"
            onClick={()=>{
                if(index === 0) setIndex(numImages-1)
                else setIndex(index-1)}}/>
            <img src={image} {...handleSwipe} alt="Project"/>
            <FontAwesomeIcon className="modalbutton" id="modalright" icon={faArrowRight} size="3x" onClick={()=>setIndex((index+1)%numImages)} />
        </div>
    );
}

export default ImageModal;