import './Projects.css';
import ImageGrid from './ImageGrid';
import ImageModal from './ImageModal';
import { useState } from 'react';


function Projects({ projects }) {

    const [selectedProject, setSelectedProject] = useState(null);

    return (
        <section id="Project" className="Project">
            <h1 className="Project_Title">Projects</h1>
            <ImageGrid projects={projects} setSelectedProject={setSelectedProject}/>
            {selectedProject && (<ImageModal selectedProject={selectedProject} setSelectedProject={setSelectedProject}/>)}
        </section>
    );
}

export default Projects;