import React from 'react'

function Concepts() {
  return (
    <section id="Concepts" class="Concepts">
        <div class="container">
            <div class="row">
                <h2 class="text-uppercase text-flex-home text-center text-md-left">Design Concepts</h2>
            </div>
            <div class="row list-inspiration mb-4">
                <div class="col-12">
                    <div class="slider-inspiration">
                                                                                    <div class="slider-item">
                                    <span data-id="60d545f2d91509001fee6469">Minimalism</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d54912d91509001fee64bc">Taiwan</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d54c9ed91509001fee6540">Zen</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d54f35d91509001fee6584">Scandinavian</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d552f5d91509001fee65cf">Rustic</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d555a8d91509001fee660f">Industrial</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d556fed91509001fee6655">Indochine</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d935f94ddc26001ed04afe">Neo Classic</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d936af4ddc26001ed04b16">Luxury</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d9376b4ddc26001ed04b30">Retro</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d93a4e4ddc26001ed04b7d">Tropical</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60e406ecb6cea9001eaf16f4">Color Block</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="617a06dadb56563545a3b250">Traditional</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d55901d91509001fee66e0">Art Deco</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d45083d91509001fee6429">Modernism</span>
                                </div>
                                                            <div class="slider-item">
                                    <span data-id="60d54aa8d91509001fee6504">Contemporary</span>
                                </div>
                                                                        </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <section class="list-discovery-style">
                        <div class="grid" id="masonry-effect">
                                                                                                <div class="grid-item grid-item_0">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd220b3c3327001e1a2a80">
                                                <img class="w-100" src="/uploads/large_white_living_room_82396dad27.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                    <div class="grid-item grid-item_1">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd22093c3327001e1a2a70">
                                                <img class="w-100" src="/uploads/large_Black_nesting_coffee_tables_33aa390618.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                    <div class="grid-item grid-item_2">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd220a3c3327001e1a2a71">
                                                <img class="w-100" src="/uploads/large_28cbb610fdb5c1fb_fa2667690f.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                    <div class="grid-item grid-item_3">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd220a3c3327001e1a2a72">
                                                <img class="w-100" src="/uploads/large_black_kitchen_cabinets_b5ad224813.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                    <div class="grid-item grid-item_4">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd220a3c3327001e1a2a73">
                                                <img class="w-100" src="/uploads/large_light_globes_4d7043bebe.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                    <div class="grid-item grid-item_5">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd220a3c3327001e1a2a74">
                                                <img class="w-100" src="/uploads/large_black_and_white_modern_kitchen_9f2364dbc8.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                    <div class="grid-item grid-item_6">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd220a3c3327001e1a2a75">
                                                <img class="w-100" src="/uploads/large_electric_fireplace_minimalist_living_room_cda5e2543b.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                    <div class="grid-item grid-item_7">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd220a3c3327001e1a2a76">
                                                <img class="w-100" src="/uploads/large_modern_floor_lamp_d2ea81ec6b.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                    <div class="grid-item grid-item_8">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd220a3c3327001e1a2a77">
                                                <img class="w-100" src="/uploads/large_modern_sofa_1_3bcd8d4e17.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                    <div class="grid-item grid-item_9">
                                        <div class="thumbnail">
                                            <a href="https://flexhome.asia/img-to-detail/60dd220a3c3327001e1a2a78">
                                                <img class="w-100" src="/uploads/large_open_plan_living_room_design_ef1d00f732.jpg" alt="pic1"/>
                                            </a>
                                        </div>
                                    </div>
                                                                                    </div>
                    </section>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 text-center">
                    <a href="https://flexhome.asia/kham-pha-phong-cach" id="fl03" class="find-inspiration">
                        Tìm cảm hứng thiết kế
                    </a>
                </div>
            </div>
        </div>
    
    </section>

    
  )
}

export default Concepts